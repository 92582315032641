body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  color: #212121;
}

.row {
  margin: 0;
}

div {
  position: relative;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
