@mixin dflex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

body {
  background: url("../../../assets/images/bodyback-image.png");
}

.section1,
.section2,
.section3,
.section4,
.section5,
.section6 {
  padding-top: 100px;
}

.section1__content {
  h1 {
    margin: 0;
    font-size: 3rem;
    color: #4458dc;
    font-weight: bold;
    padding-bottom: 10px;
  }
}

.social__links {
  padding: 20px 0;
  a {
    padding-right: 20px;
    svg {
      width: 20px;
      height: 20px;
      &:hover {
        path {
          &:first-child {
            fill: #4458dc;
            transition: .2s;
          }
        }
      }
    }
  }
}

.section1__content__typewriter {
  span {
    margin: 0;
    font-size: 1.875rem;
    font-weight: bold;
  }
}

.section1__downloadcvbtn {
  display: inline-block;
  padding: 0;
  padding-bottom: 0.5px;
  padding-right: 0.5px;
  color: #4458dc;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 46px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: .5s;
  border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box , border-box;
  background-image: linear-gradient(#fff,#fff) , radial-gradient(circle at top left,#4458dc,#854fee);
  border: 2px solid transparent;
  box-shadow: none;
  &:hover {
    background-image: linear-gradient(to right,#4458dc 0%,#854fee 100%) , radial-gradient(circle at top left,#4458dc,#854fee);
    border: 2px solid transparent;
    box-shadow: 0 10px 30px rgba(118,85,225,.3);
    outline: 0 none;
    color: #FFFFFF;
  }
  &:focus {
    outline: 0 none;
  }
  span {
    padding: 0 20px;
  }
}

.section1__usersvg, .section2__usersvg {
  img {
    width: 100%;
  }
}

.section2__usersvg {
  @media screen and (max-width: 767px) {
    img {
      display: none;
    }
  }
}

.section2__content {
  margin-left: 8.33333%;
  h1 {
    margin: 0;
    font-size: 1.875rem;
    font-weight: bold;
    padding-bottom: 10px;
    letter-spacing: 2px;
  }
  h2 {
    font-weight: bolder;
    font-size: 1rem;
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 8px 0;
    span {
      font-weight: normal;
      a {
        color: #4458dc;
        &:hover {
          color: #4458dc;
        }
      }
    }
  }
}

.section3__myskills {
  h2 {
    margin: 0;
    font-size: 1.875rem;
    font-weight: bold;
    padding-bottom: 10px;
    letter-spacing: 2px;
  }
}

.section3__myskills__types {
  padding-top: 50px;
}

.myskills__content__progress__rating {
  @include dflex();
  height: 15px;
  border-radius: 10px;
  background-color: #CECECE;
  .myskills__content__progress__rating--filled {
    background: linear-gradient(to right,#4458dc 0%,#854fee 100%) , radial-gradient(circle at top left,#4458dc,#854fee);
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.section3__myskills__content {
  margin-bottom: 50px;
  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 20px;
  }
}

.section4__work, .section5__resume {
  h1 {
    margin: 0;
    font-size: 1.875rem;
    font-weight: bold;
    padding-bottom: 10px;
    letter-spacing: 2px;
    text-align: center;
  }
}

.section4__work__list {
  @include dflex();
  justify-content: center;
  -webkit-justify-content: center;
}

.section4__work__list {
  ul {
    li {
      font-weight: 600;
      text-transform: capitalize;
      display: inline-block;
      cursor: pointer;
      padding: 4px 17px;
      font-size: 0.875rem;
      transition: .5s;
      letter-spacing: 1px;
      background: #f5f5f5;
      margin: 0 3px 8px;
      border-radius: 30px;
      user-select: none;
    }
    li.list__active{
      background: linear-gradient(to right,#4458dc 0%,#854fee 100%) , radial-gradient(circle at top left,#4458dc,#854fee);
      color: #fff;
    }
  }
}

.img-grid{
  margin: 20px auto;
}
.img-wrap{
  overflow: hidden;
  padding: 15px;
  position: relative;
  .img-wrap__content {
    position: relative;
    transition: .3s;
    .img-wrap__content__text {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      visibility: hidden;
      transition: .2s;
      a {
        transform: translate(-50px, -25px);
        transition: .4s;
        padding: 0 5px;
        svg {
          width: 32px;
          height: 25px;
        }
      }
    }
    &:hover {
      .img-wrap__content__text {
        visibility: visible;
        opacity: 1;
        background-color: rgba(255,255,255,0.6);
        a {
          transform: translate(0, 0);
        }
      }
    }
  }
}
.img-wrap img{
  width: 100%;
}

.section5__resume {
  h2 {
    font-size: 1.625rem;
    font-weight: bolder;
    margin: 0;
    padding: 15px 0;
  }
  .section5__resume__work {
    display: flex;
    flex-direction: row;
    .section5__resume__work__dotline {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .section5__resume__work__dot {
      background-color: #4458dc;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: 8px 0;
    }
    
    .section5__resume__work__line {
      background-color: #4458dc;
      min-height: 0px;
      position: relative;
      width: 8px;
      flex: 1;
      &::before, &::after {
        border-radius: 50%;
        height: 4.44px;
        width: 8px;
        content: '';
        display: block;
        position: absolute;
      }
      &::before {
        top: -4.44px;
        box-shadow: 0 2.22px 0 0 #4458dc;
      }
      &::after {
        bottom: -4.44px;
        box-shadow: 0 -2.22px 0 0 #4458dc;
      }
    }
    .section5__resume__work__text {
      padding-bottom: 50px;
      margin: 0 0 0 15px;
      h3 {
        color: #4458dc;
        font-size: 1rem;
        font-weight: bold;
        margin: 0 0 5px 0;
        text-transform: uppercase;
      }
      p {
        font-size: 1rem;
        margin: 0;
      }
      .section5__resume__work__text__title {
        // display: inline-block;
        // align-items: center;
        h3 {
          margin-right: 20px;
          display: inline-block;
        }
      }
      span {
        background-color: #f5f5f5;
        padding: 2px 10px;
        border-radius: 4px;
        font-weight: 500;
        color: #696969;
        margin-bottom: 5px;
        display: inline-block;
        width: -webkit-max-content;
        width: max-content;
      }
      ul {
        li {
          font-style: italic;
          padding-top: 10px;
        }
      }
    }
  }
}

.section6 {
  h2 {
    font-size: 1.0625rem;
    margin: 0;
    padding-bottom: 5px;
  }
  p {
    color: #696969;
    font-weight: 500;
    padding-bottom: 15px;
    margin: 0;
  }
  .card {
    background-color: #f8f9f8;
  }
  .img-wrap__text__link {
    color: #4458dc;
    text-decoration: none;
    display: block;
  }
}

.comming__soon {
  font-size: 0.875rem;
  margin: 15px 0;
  padding: 5px 10px;
  text-align: center;
  color: #4458dc;
  border: 1.8px dashed #4458dc;
  border-radius: 8px;
}

.footer {
  background-color: #f5f5f5;
  text-align: center;
  padding: 20px 0;
  h1 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: bolder;
    padding-bottom: 15px;
  }
  p {
    margin: 0;
    color: #696969;
    font-weight: 500;
  }
}

.footer {
  .social__links {
    a {
      padding: 0 10px;
      }
    }
  }