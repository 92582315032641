@mixin dflex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.header__start {
  padding: 10px 0;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  -moz-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  -ms-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  -o-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.header__logo {
  a {
    margin: 0;
    font-size: 2.5rem;
    color: #4458dc;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: #4458dc;
    }
  }
}

.navbar__list {
  @include dflex();
  align-items: center;
  justify-content: flex-end;
  ul {
    display: flex;
    align-items: center;
    li {
      a {
        display: block;
        padding: 7px 15px;
        font-size: 1rem;
        font-weight: 600;
        color: #212121;
        text-decoration: none;
        cursor: pointer;
        transition: all .2s linear;
        user-select: none;
        &:hover {
          color: #4458dc;
        }
      }
      @media screen and (max-width: 991px) {
        a {
          color: #FFFFFF ;
        }
      }
    }
    li {
      a.navbar__list__active {
        color: #4458dc;
      }
    }
  }
}

.menubar-overlay {
  position: fixed;
  top: 0;
  left: 0%;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: none;
}

.header__navbar__menu--btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .5s ease-in-out;
  border: 0;
  height: 25px;
  justify-content: flex-end;
}
.header__navbar__menu--btn__icon {
  width: 30px;
  height: 2px;
  background: #233A51;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(35,58,81,.2);
  transition: all .5s ease-in-out;
}
.header__navbar__menu--btn__icon::before,
.header__navbar__menu--btn__icon::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background: #233A51;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(35,58,81,.2);
  transition: all .5s ease-in-out;
}
.header__navbar__menu--btn__icon::before {
  transform: translateY(-10px);
}
.header__navbar__menu--btn__icon::after {
  transform: translateY(10px);
}
/* ANIMATION */
.header__navbar__menu--btn.open .header__navbar__menu--btn__icon {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.header__navbar__menu--btn.open .header__navbar__menu--btn__icon::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.header__navbar__menu--btn.open .header__navbar__menu--btn__icon::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.show-overlay,
.menubar__close {
  display: block;
}

.menubar__close {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #FFFFFF;
    }
  }
}

@media screen and (min-width: 992px) {
  .menubar-overlay,
  .header__navbar__menu--btn,
  .show-overlay,
  .menubar__close {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .navbar__list {
    background-color: #233A51;
    ul {
      display: block;
      padding: 50px 15px;
    }
    display: block;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    width: 200px;
    transition: .2s;
    transform: translateX(200px);
    z-index: 100;
  }
  .navbar__list__translate {
    transform: translateX(0);
  }
}